import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  RegionImpl,
  RegionDetailModel,
  watchActiveRegion,
  watchEnabledRegions,
} from 'shell-browser/region.js';

import { NgShellRxjsWrapperService } from './shell/ng-shell-wrapper.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  readonly ngShellService = inject(NgShellRxjsWrapperService);
  readonly translocoService = inject(TranslocoService);
  router = inject(Router);

  regionalizedRoute: string = '';

  ngOnInit(): void {
    this.getShellRouters();
    this.setShellLanguages();
  }

  private getShellRouters(): void {
    this.ngShellService
      .toObservable(watchActiveRegion)
      .pipe(untilDestroyed(this))
      .subscribe((region: RegionImpl) => {
        if (region.country && region.ietf) {
          this.regionalizedRoute = `${region.country}/${region.ietf.toLowerCase()}`;
          this.refreshRouteAfterLanguageChange();
          this.translocoService.setActiveLang(region.ietf);
        }
      });
  }

  getRouterLink(route: string): string {
    return `${this.regionalizedRoute}/${route}`;
  }

  private refreshRouteAfterLanguageChange(): void {
    const currentRouteWithoutRegionalization =
      this.router?.url.split('/').pop() ?? 'main';
    const newRoute = this.getRouterLink(currentRouteWithoutRegionalization);

    this.router.navigateByUrl(newRoute);
  }

  private setShellLanguages(): void {
    this.ngShellService
      .toObservable(watchEnabledRegions)
      .pipe(untilDestroyed(this))
      .subscribe((regions: RegionDetailModel[]) => {
        const languageList = regions
          .flatMap(region => region.languages)
          .map(
            l =>
              `${l.language.substring(0, 2)}${l.language.substring(2, 5).toUpperCase()}`,
          )
          .filter((value, index, self) => self.indexOf(value) === index);

        //Add default language from transloco to the list of possible languages even it its not available as the shell langauge
        //Safety fallback for when there is no translation file for created region
        const defaultTranslocoLang = this.translocoService.getDefaultLang();
        languageList.push(defaultTranslocoLang);

        this.translocoService.setAvailableLangs(languageList);
      });
  }
}
