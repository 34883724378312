const clamp = (value, min = 0, max = 1) => Math.min(Math.max(min, value), max);
const hexToRgb = input => {
  const color = input.slice(1);
  const re = new RegExp(`.{1,${color.length >= 6 ? 2 : 1}}`, 'g');
  let colors = color.match(re);
  if (colors && colors[0].length === 1) {
    colors = colors.map(n => n + n);
  }
  return colors ? `rgb${colors.length === 4 ? 'a' : ''}(${colors.map((n, index) => index < 3 ? parseInt(n, 16) : Math.round(parseInt(n, 16) / 255 * 1000) / 1000).join(', ')})` : '';
};
const decomposeColor = color => {
  if (color.charAt(0) === '#') {
    return decomposeColor(hexToRgb(color));
  }
  const marker = color.indexOf('(');
  const type = color.substring(0, marker);
  const valuesAsString = color.substring(marker + 1, color.length - 1);
  const valuesArray = valuesAsString.split(',');
  const values = valuesArray.map(value => parseFloat(value));
  return {
    type,
    values
  };
};
const alpha = (color, value) => {
  const decomposedColor = decomposeColor(color);
  const clampedValue = clamp(value);
  decomposedColor.type === 'rgb' && (decomposedColor.type += 'a');
  decomposedColor.values[3] = clampedValue;
  return `${decomposedColor.type}(${decomposedColor.values.join(', ')})`;
};
export { alpha as a, decomposeColor as d, hexToRgb as h };