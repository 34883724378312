import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { OWC_GLOBAL_KEY, RUNTIME_FWK_GLOBAL_KEY } from '@one/web-components';
import { defineCustomElement as defineCustomElement$1 } from '@one/web-components/components/owc-native-table.js';
import { defineCustomElement as defineCustomElement$2 } from '@one/web-components/components/owc-table.js';
import { defineCustomElement as defineCustomElement$3 } from '@one/web-components/components/owc-table-body.js';
import { defineCustomElement as defineCustomElement$4 } from '@one/web-components/components/owc-table-cell.js';
import { defineCustomElement as defineCustomElement$5 } from '@one/web-components/components/owc-table-group.js';
import { defineCustomElement as defineCustomElement$6 } from '@one/web-components/components/owc-table-header.js';
import { defineCustomElement as defineCustomElement$7 } from '@one/web-components/components/owc-table-header-cell.js';
import { defineCustomElement as defineCustomElement$8 } from '@one/web-components/components/owc-table-row.js';
import { defineCustomElement as defineCustomElement$9 } from '@one/web-components/components/owc-table-simple-cell.js';
const _c0 = ["*"];
const DIRECTIVES = [];

/* eslint-disable */
const fixNestedFunctionCalls = (val, self) => {
  if (val instanceof Element || val instanceof Document) {
    return val;
  } else if (Array.isArray(val)) {
    return val.map(v => fixNestedFunctionCalls(v, self));
  } else if (val === null || val === undefined) {
    return val;
  } else if (val instanceof Date) {
    return new Date(val.getTime());
  } else if (typeof val === 'object') {
    const fixedObj = {};
    for (const key of Object.keys(val)) {
      fixedObj[key] = fixNestedFunctionCalls(val[key], self);
    }
    return fixedObj;
  } else if (typeof val === 'function') {
    return (...args) => self.z.runTask(() => val(...args));
  } else {
    return val;
  }
};
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = fixNestedFunctionCalls(val, this));
      }
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  //   events.forEach(eventName => (instance[eventName] = fromEvent(el, eventName)));
  events.forEach(eventName => instance[eventName] = new EventEmitter());
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (typeof window !== 'undefined' && OWC_GLOBAL_KEY in window && !(RUNTIME_FWK_GLOBAL_KEY in window[OWC_GLOBAL_KEY])) {
      Object.defineProperty(window[OWC_GLOBAL_KEY], RUNTIME_FWK_GLOBAL_KEY, {
        configurable: true,
        enumerable: true,
        writable: true,
        value: 'angular'
      });
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
defineCustomElement$1();
let OwcNativeTable = class OwcNativeTable {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcNativeTable.ɵfac = function OwcNativeTable_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcNativeTable)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcNativeTable.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcNativeTable,
  selectors: [["owc-native-table"]],
  inputs: {
    elevated: "elevated",
    nativeTableProps: "nativeTableProps",
    rounded: "rounded",
    size: "size",
    verticalBorder: "verticalBorder"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcNativeTable_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcNativeTable = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1,
  inputs: ['elevated', 'nativeTableProps', 'rounded', 'size', 'verticalBorder']
})], OwcNativeTable);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcNativeTable, [{
    type: Component,
    args: [{
      selector: 'owc-native-table',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['elevated', 'nativeTableProps', 'rounded', 'size', 'verticalBorder']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$2();
let OwcTable = class OwcTable {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['scrollChange', 'selectedRowsChange']);
  }
};
OwcTable.ɵfac = function OwcTable_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTable)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTable.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTable,
  selectors: [["owc-table"]],
  inputs: {
    alignRowWithGroup: "alignRowWithGroup",
    cardProps: "cardProps",
    elevated: "elevated",
    horizontalBorder: "horizontalBorder",
    loading: "loading",
    rounded: "rounded",
    showGroupExpanderColumn: "showGroupExpanderColumn",
    showRowExpanderColumn: "showRowExpanderColumn",
    showSelectionColumn: "showSelectionColumn",
    size: "size",
    sortableRows: "sortableRows",
    stickyColumnBeginning: "stickyColumnBeginning",
    stickyColumnEnd: "stickyColumnEnd",
    verticalBorder: "verticalBorder"
  },
  outputs: {
    scrollChange: "scrollChange",
    selectedRowsChange: "selectedRowsChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTable_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTable = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$2,
  inputs: ['alignRowWithGroup', 'cardProps', 'elevated', 'horizontalBorder', 'loading', 'rounded', 'showGroupExpanderColumn', 'showRowExpanderColumn', 'showSelectionColumn', 'size', 'sortableRows', 'stickyColumnBeginning', 'stickyColumnEnd', 'verticalBorder'],
  methods: ['scrollToBottom', 'scrollToLeft', 'scrollToRight', 'scrollToTop']
})], OwcTable);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTable, [{
    type: Component,
    args: [{
      selector: 'owc-table',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['alignRowWithGroup', 'cardProps', 'elevated', 'horizontalBorder', 'loading', 'rounded', 'showGroupExpanderColumn', 'showRowExpanderColumn', 'showSelectionColumn', 'size', 'sortableRows', 'stickyColumnBeginning', 'stickyColumnEnd', 'verticalBorder'],
      outputs: ['scrollChange', 'selectedRowsChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$3();
let OwcTableBody = class OwcTableBody {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['rowCountChange']);
  }
};
OwcTableBody.ɵfac = function OwcTableBody_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTableBody)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableBody.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableBody,
  selectors: [["owc-table-body"]],
  outputs: {
    rowCountChange: "rowCountChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableBody_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableBody = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$3,
  inputs: []
})], OwcTableBody);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableBody, [{
    type: Component,
    args: [{
      selector: 'owc-table-body',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: [],
      outputs: ['rowCountChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$4();
let OwcTableCell = class OwcTableCell {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['contentChange', 'editingChange']);
  }
};
OwcTableCell.ɵfac = function OwcTableCell_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTableCell)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableCell.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableCell,
  selectors: [["owc-table-cell"]],
  inputs: {
    align: "align",
    alternate: "alternate",
    disabled: "disabled",
    editable: "editable",
    editing: "editing",
    hostId: "hostId",
    hostRole: "hostRole",
    parentDisabled: "parentDisabled",
    sticky: "sticky",
    tabbable: "tabbable",
    valign: "valign"
  },
  outputs: {
    contentChange: "contentChange",
    editingChange: "editingChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableCell_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableCell = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$4,
  inputs: ['align', 'alternate', 'disabled', 'editable', 'editing', 'hostId', 'hostRole', 'parentDisabled', 'sticky', 'tabbable', 'valign'],
  methods: ['getKeydownManager']
})], OwcTableCell);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableCell, [{
    type: Component,
    args: [{
      selector: 'owc-table-cell',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['align', 'alternate', 'disabled', 'editable', 'editing', 'hostId', 'hostRole', 'parentDisabled', 'sticky', 'tabbable', 'valign'],
      outputs: ['contentChange', 'editingChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$5();
let OwcTableGroup = class OwcTableGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['expandChange', 'selectChange']);
  }
};
OwcTableGroup.ɵfac = function OwcTableGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTableGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableGroup,
  selectors: [["owc-table-group"]],
  inputs: {
    checkboxProps: "checkboxProps",
    defaultIndentation: "defaultIndentation",
    disabled: "disabled",
    expandButtonProps: "expandButtonProps",
    expandable: "expandable",
    expanded: "expanded",
    header: "header",
    hostId: "hostId",
    indentation: "indentation",
    selectable: "selectable",
    selected: "selected",
    sticky: "sticky",
    tabbable: "tabbable"
  },
  outputs: {
    expandChange: "expandChange",
    selectChange: "selectChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableGroup = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$5,
  inputs: ['checkboxProps', 'defaultIndentation', 'disabled', 'expandButtonProps', 'expandable', 'expanded', 'header', 'hostId', 'indentation', 'selectable', 'selected', 'sticky', 'tabbable'],
  methods: ['getKeydownManager']
})], OwcTableGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableGroup, [{
    type: Component,
    args: [{
      selector: 'owc-table-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['checkboxProps', 'defaultIndentation', 'disabled', 'expandButtonProps', 'expandable', 'expanded', 'header', 'hostId', 'indentation', 'selectable', 'selected', 'sticky', 'tabbable'],
      outputs: ['expandChange', 'selectChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$6();
let OwcTableHeader = class OwcTableHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcTableHeader.ɵfac = function OwcTableHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTableHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableHeader,
  selectors: [["owc-table-header"]],
  inputs: {
    checkboxProps: "checkboxProps",
    leadingCellProps: "leadingCellProps",
    sticky: "sticky"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableHeader = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$6,
  inputs: ['checkboxProps', 'leadingCellProps', 'sticky']
})], OwcTableHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableHeader, [{
    type: Component,
    args: [{
      selector: 'owc-table-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['checkboxProps', 'leadingCellProps', 'sticky']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$7();
let OwcTableHeaderCell = class OwcTableHeaderCell {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dragResize', 'sortOrderChange', 'widthChange']);
  }
};
OwcTableHeaderCell.ɵfac = function OwcTableHeaderCell_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTableHeaderCell)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableHeaderCell.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableHeaderCell,
  selectors: [["owc-table-header-cell"]],
  inputs: {
    align: "align",
    disabled: "disabled",
    hostId: "hostId",
    resizable: "resizable",
    sortIconProps: "sortIconProps",
    sortIconVisibility: "sortIconVisibility",
    sortOrder: "sortOrder",
    sortable: "sortable",
    sticky: "sticky",
    tabbable: "tabbable",
    valign: "valign",
    width: "width",
    wrap: "wrap"
  },
  outputs: {
    dragResize: "dragResize",
    sortOrderChange: "sortOrderChange",
    widthChange: "widthChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableHeaderCell_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableHeaderCell = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$7,
  inputs: ['align', 'disabled', 'hostId', 'resizable', 'sortIconProps', 'sortIconVisibility', 'sortOrder', 'sortable', 'sticky', 'tabbable', 'valign', 'width', 'wrap'],
  methods: ['getKeydownManager']
})], OwcTableHeaderCell);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableHeaderCell, [{
    type: Component,
    args: [{
      selector: 'owc-table-header-cell',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['align', 'disabled', 'hostId', 'resizable', 'sortIconProps', 'sortIconVisibility', 'sortOrder', 'sortable', 'sticky', 'tabbable', 'valign', 'width', 'wrap'],
      outputs: ['dragResize', 'sortOrderChange', 'widthChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$8();
let OwcTableRow = class OwcTableRow {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['expandedChange', 'indexChange', 'selectedChange']);
  }
};
OwcTableRow.ɵfac = function OwcTableRow_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTableRow)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableRow.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableRow,
  selectors: [["owc-table-row"]],
  inputs: {
    checkboxProps: "checkboxProps",
    clicked: "clicked",
    disabled: "disabled",
    expandButtonProps: "expandButtonProps",
    expandable: "expandable",
    expanded: "expanded",
    leadingCellProps: "leadingCellProps",
    locked: "locked",
    selectable: "selectable",
    selected: "selected",
    tabbable: "tabbable",
    trailingCellProps: "trailingCellProps"
  },
  outputs: {
    expandedChange: "expandedChange",
    indexChange: "indexChange",
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableRow_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableRow = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$8,
  inputs: ['checkboxProps', 'clicked', 'disabled', 'expandButtonProps', 'expandable', 'expanded', 'leadingCellProps', 'locked', 'selectable', 'selected', 'tabbable', 'trailingCellProps']
})], OwcTableRow);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableRow, [{
    type: Component,
    args: [{
      selector: 'owc-table-row',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['checkboxProps', 'clicked', 'disabled', 'expandButtonProps', 'expandable', 'expanded', 'leadingCellProps', 'locked', 'selectable', 'selected', 'tabbable', 'trailingCellProps'],
      outputs: ['expandedChange', 'indexChange', 'selectedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$9();
let OwcTableSimpleCell = class OwcTableSimpleCell {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcTableSimpleCell.ɵfac = function OwcTableSimpleCell_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTableSimpleCell)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableSimpleCell.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableSimpleCell,
  selectors: [["owc-table-simple-cell"]],
  inputs: {
    align: "align",
    alternate: "alternate",
    disabled: "disabled",
    hostId: "hostId",
    hostRole: "hostRole",
    parentDisabled: "parentDisabled",
    sticky: "sticky",
    tabbable: "tabbable",
    valign: "valign"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableSimpleCell_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableSimpleCell = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$9,
  inputs: ['align', 'alternate', 'disabled', 'hostId', 'hostRole', 'parentDisabled', 'sticky', 'tabbable', 'valign'],
  methods: ['getKeydownManager']
})], OwcTableSimpleCell);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableSimpleCell, [{
    type: Component,
    args: [{
      selector: 'owc-table-simple-cell',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['align', 'alternate', 'disabled', 'hostId', 'hostRole', 'parentDisabled', 'sticky', 'tabbable', 'valign']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
const COMPONENTS = [OwcNativeTable, OwcTable, OwcTableBody, OwcTableCell, OwcTableGroup, OwcTableHeader, OwcTableHeaderCell, OwcTableRow, OwcTableSimpleCell];
class TableModule {}
TableModule.ɵfac = function TableModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TableModule)();
};
TableModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TableModule
});
TableModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TableModule, [{
    type: NgModule,
    args: [{
      declarations: [...COMPONENTS, ...DIRECTIVES],
      imports: [],
      exports: [...COMPONENTS, ...DIRECTIVES]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DIRECTIVES, OwcNativeTable, OwcTable, OwcTableBody, OwcTableCell, OwcTableGroup, OwcTableHeader, OwcTableHeaderCell, OwcTableRow, OwcTableSimpleCell, TableModule };
