import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { Config } from '../models/config.model';

@Injectable({
  providedIn: 'root',
})
export class EnvConfigService {
  public config: Config | null = null;
  public header = {};

  public constructor(public http: HttpClient) {}

  public async Init(): Promise<void> {
    const appUrl = window.location.origin;
    const url = `${appUrl}/api/v1/envconfig`;
    this.config = await this.getConfig(url);

    if (window.location.origin.includes('localhost')) {
      this.config.environmentName += '-localhost';
    }
  }

  public getConfig(url: string): Promise<Config> {
    return firstValueFrom(this.http.get<Config>(url, this.header));
  }
}
