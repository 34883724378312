import {
  RegionImpl,
  ResolveRegion,
  unregionizeUrl,
  urlToSegments,
} from 'shell-browser/region.js';

/**
 * Custom URL resolver serivice, used in InitShellApp() in place of BuiltInResolvers.url
 * BuiltInResolvers.url is currently not working as expected
 */
export class UrlResolverService implements ResolveRegion {
  async resolve(): Promise<RegionImpl | null> {
    try {
      const path: string = window.location.pathname;
      return this.resolveLocaleFromUrl(path);
    } catch (error) {
      console.error('Error resolving URL:', error);
      return null;
    }
  }

  resolveLocaleFromUrl(path: string): RegionImpl | null {
    const segments = urlToSegments(path);
    const unregionized = unregionizeUrl(segments);
    if (!unregionized.region.isLocaleFormat()) {
      throw new Error('Region is not in locale format');
    }
    return unregionized.region;
  }
}
