import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializerProvider } from './core/app-initializer/app-initializer.service';
import { ENVIRONMENT } from './core/models/environment.token';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [BrowserModule, AppRoutingModule, TranslocoRootModule],
  providers: [
    appInitializerProvider,
    ...environment.providers,
    { provide: ENVIRONMENT, useValue: environment },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
