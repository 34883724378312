import { p as proxyCustomElement, H, d as createEvent, h, c as Host } from './p-25bf8c52.js';
import { S as Sortable } from './p-3b547abb.js';
import { C as CTX_KEYS } from './p-e5c1eb43.js';
import { i as isSSR } from './p-604842db.js';
import { b as accessibility } from './p-756e7dc5.js';
import { C as createBEMModifier } from './p-a00c815e.js';
import { a as ComponentUtils, j as debounce } from './p-cf58d171.js';
import { a as Context, O as OnContextChange, C as ContextValue } from './p-4ae29297.js';
import { O as OnElementMutation } from './p-f0ee7b1d.js';
const tableBodyCss = ".sc-owc-table-body-h{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-positive:1;flex-grow:1}.sc-owc-table-body-h [slot=empty].sc-owc-table-body{display:none}.owc-table-body--dragging.sc-owc-table-body-h{cursor:-webkit-grabbing;cursor:grabbing}.owc-table-body--empty.sc-owc-table-body-h [slot=empty].sc-owc-table-body{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-positive:1;flex-grow:1}";
const OwcTableBodyStyle0 = tableBodyCss;
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const TABLE_TAG = 'owc-table';
const ROW_TAG = 'owc-table-row';
const GROUP_TAG = 'owc-table-group';
const DRAGGING_DELAY_MS = 200;
const DRAGGING_ANIMATION_DURATION_MS = 400;
const ROW_CHANGE_DEBOUNCE_TIMEOUT = 100;
const CELL_TAG = 'owc-table-cell';
const SIMPLE_CELL_TAG = 'owc-table-simple-cell';
const CELL_SELECTOR = [CELL_TAG, SIMPLE_CELL_TAG].map(s => `${ROW_TAG} > ${s}`).join(', ');
const TableBody = /*@__PURE__*/proxyCustomElement(class TableBody extends H {
  constructor() {
    super();
    this.__registerHost();
    this.rowCountChange = createEvent(this, "rowCountChange", 7);
    this.ctxTableCalculations = new ContextValue();
    this.component = new ComponentUtils(this);
    this.emitRowCountChange = count => this.rowCountChange.emit(count);
    this.emitRowCountChangeDebounced = debounce(this.emitRowCountChange, ROW_CHANGE_DEBOUNCE_TIMEOUT);
    this.getCells = () => {
      var _a;
      return Array.from(((_a = this.hostElement) === null || _a === void 0 ? void 0 : _a.querySelectorAll(CELL_SELECTOR)) || []);
    };
    this.checkGroup = () => !!this.hostElement.querySelector(GROUP_TAG);
    this.setSortableInstance = () => {
      if (!isSSR() && !this.sortableInstance && this.hostElement) {
        this.sortableInstance = Sortable.create(this.hostElement, {
          draggable: ROW_TAG,
          group: 'sortable-group',
          filter: `${ROW_TAG}[locked]`,
          ghostClass: createBEMModifier(ROW_TAG, 'dropzone'),
          chosenClass: createBEMModifier(ROW_TAG, 'dragging'),
          sort: true,
          removeCloneOnHide: true,
          preventOnFilter: false,
          delay: DRAGGING_DELAY_MS,
          animation: DRAGGING_ANIMATION_DURATION_MS,
          onStart: () => this.dragging = true,
          onEnd: ev => {
            this.dragging = false;
            ev.item.dispatchEvent(new CustomEvent('indexChange', {
              detail: ev.newIndex - 1
            }));
          }
        });
      }
    };
    this.removeSortableInstance = () => {
      this.sortableInstance && this.sortableInstance.destroy();
      this.sortableInstance = undefined;
    };
    this.updateStickyStyle = calculations => {
      var _a;
      if ((_a = calculations === null || calculations === void 0 ? void 0 : calculations.rowCells) === null || _a === void 0 ? void 0 : _a.length) {
        const rowCellCalculations = calculations.rowCells;
        const hasGroup = !!this.checkGroup();
        this.getCells().forEach((cell, index) => {
          var _a;
          const calcIndex = index % calculations.rowCells.length;
          const calcNextIndex = Math.min((index + 1) % calculations.rowCells.length, calculations.rowCells.length);
          const {
            left,
            right,
            sticky,
            zIndex
          } = rowCellCalculations[calcIndex];
          sticky ? cell.setAttribute('sticky', sticky) : cell.removeAttribute('sticky');
          !sticky.includes('start') ? cell.style.removeProperty('left') : cell.style.setProperty('left', calcIndex === 1 && hasGroup ? `calc(${((_a = rowCellCalculations[calcNextIndex]) === null || _a === void 0 ? void 0 : _a.left) || `${cell.clientWidth}px`} - ${cell.clientWidth}px)` : left);
          !sticky.includes('end') ? cell.style.removeProperty('right') : cell.style.setProperty('right', right);
          zIndex === undefined ? cell.style.removeProperty('z-index') : cell.style.setProperty('z-index', zIndex);
        });
      }
    };
    this.dragging = false;
    this.rowCount = 0;
    this.tableConfig = undefined;
    this.tableCalculations = undefined;
  }
  onExpandChange(event) {
    !!event.detail && setTimeout(() => this.updateStickyStyle(this.tableCalculations));
  }
  onConfigContextChange(config) {
    (config === null || config === void 0 ? void 0 : config.sortableRows) ? this.setSortableInstance() : this.removeSortableInstance();
    this.tableConfig = Object.assign({}, config);
  }
  onCalculationContextChange(calculations) {
    this.updateStickyStyle(calculations);
    this.tableCalculations = Object.assign({}, calculations);
  }
  onRowMutation() {
    var _a;
    const count = Array.from(this.hostElement.children).filter(el => {
      var _a;
      return [ROW_TAG, GROUP_TAG].includes((_a = el === null || el === void 0 ? void 0 : el.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase());
    }).length;
    if (count !== this.rowCount) {
      this.rowCount = count;
      this.emitRowCountChangeDebounced(count);
      this.onCalculationContextChange((_a = this.ctxTableCalculations) === null || _a === void 0 ? void 0 : _a.get());
    }
  }
  componentDidLoad() {
    this.onRowMutation();
  }
  disconnectedCallback() {
    this.removeSortableInstance();
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      dragging: this.dragging,
      empty: !this.rowCount
    });
    return h(Host, Object.assign({
      key: 'abfc6a2258e4c25f06283aeb5235e0a02ef0301a',
      class: hostClasses
    }, accessibility({
      role: 'rowgroup'
    })), h("slot", {
      key: 'd96691a81e5f8fd8408358a9ca4452fe5f33b7b0'
    }), !this.rowCount ? h("slot", {
      name: "empty"
    }) : null);
  }
  get hostElement() {
    return this;
  }
  static get style() {
    return OwcTableBodyStyle0;
  }
}, [6, "owc-table-body", {
  "dragging": [32],
  "rowCount": [32],
  "tableConfig": [32],
  "tableCalculations": [32]
}, [[0, "expandChange", "onExpandChange"]]]);
__decorate([Context(CTX_KEYS.TABLE.CALCULATIONS)], TableBody.prototype, "ctxTableCalculations", void 0);
__decorate([OnContextChange(CTX_KEYS.TABLE.CONFIG, {
  target: TABLE_TAG,
  stage: 'didLoad'
})], TableBody.prototype, "onConfigContextChange", null);
__decorate([OnContextChange(CTX_KEYS.TABLE.CALCULATIONS, {
  target: TABLE_TAG,
  stage: 'didLoad'
})], TableBody.prototype, "onCalculationContextChange", null);
__decorate([OnElementMutation({
  target: hostInstance => hostInstance.hostElement,
  childList: true,
  subtree: true
})], TableBody.prototype, "onRowMutation", null);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-table-body"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-table-body":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, TableBody);
        }
        break;
    }
  });
}
const OwcTableBody = TableBody;
const defineCustomElement = defineCustomElement$1;
export { OwcTableBody, defineCustomElement };