import { p as proxyCustomElement, H, d as createEvent, h, c as Host } from './p-25bf8c52.js';
import { a as getBody, E as isPositiveNumber } from './p-604842db.js';
import { a as alpha } from './p-ac673a64.js';
import { A as getDesignTokenValue } from './p-a00c815e.js';
import { a as ComponentUtils } from './p-cf58d171.js';
import { O as OnElementMutation } from './p-f0ee7b1d.js';
const backdropCss = "*.sc-owc-backdrop{scrollbar-width:thin;scrollbar-color:var(--one-color-interaction-default-intensity-low) transparent;scroll-margin:initial;scroll-padding:3px}.sc-owc-backdrop::-webkit-scrollbar{width:12px;height:12px}.sc-owc-backdrop::-webkit-scrollbar-track{background:transparent}.sc-owc-backdrop::-webkit-scrollbar-corner{background-color:transparent}.sc-owc-backdrop::-webkit-scrollbar-thumb{background-color:var(--one-color-interaction-default-intensity-low);border-radius:calc(3px + 4px);border:3px solid rgba(255, 255, 255, 0);background-clip:padding-box}.sc-owc-backdrop::-webkit-scrollbar-thumb:hover{background-color:var(--one-color-interaction-hover-intensity-medium);border-radius:calc(3px + 4px);border:3px solid rgba(255, 255, 255, 0);background-clip:padding-box}.sc-owc-backdrop::-webkit-scrollbar-thumb:active{background:var(--one-color-interaction-focus-intensity-higher);border-radius:calc(3px + 4px);border:3px solid rgba(255, 255, 255, 0);background-clip:padding-box}.sc-owc-backdrop-h{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;min-height:100%;max-height:100%;height:100%;min-width:100%;max-width:100%;width:100%;-webkit-box-sizing:border-box;box-sizing:border-box;position:fixed;top:0;left:0;right:0;bottom:0;background-color:var(--backdrop-opacity);padding:var(--backdrop-padding, var(--one-spacing-padding-s));overflow:hidden;-webkit-transition:all 0.5s;transition:all 0.5s;z-index:var(--one-z-index-backdrop)}.owc-backdrop--hidden.sc-owc-backdrop-h{display:none}.sc-owc-backdrop-h .owc-backdrop__backdrop-content.sc-owc-backdrop{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;display:contents;max-height:100%;max-width:100%;overflow:hidden;-webkit-transition:all 0.5s;transition:all 0.5s}";
const OwcBackdropStyle0 = backdropCss;
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const DEFAULT_OPACITY = parseFloat(getDesignTokenValue('--one-backdrop-opacity'));
const BACKDROP_OPACITY_VARIABLE = '--backdrop-opacity';
const STYLE_CLEAR_TIMEOUT = 100;
const Backdrop = /*@__PURE__*/proxyCustomElement(class Backdrop extends H {
  constructor() {
    super();
    this.__registerHost();
    this.backdropClick = createEvent(this, "backdropClick", 3);
    this.component = new ComponentUtils(this);
    this.handleBodyOverflow = () => {
      var _a, _b, _c, _d;
      if (!this.disableScrollLock && !this.hidden) {
        (_b = (_a = getBody()) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.setProperty('overflow', 'hidden', 'important');
      } else {
        (_d = (_c = getBody()) === null || _c === void 0 ? void 0 : _c.style) === null || _d === void 0 ? void 0 : _d.removeProperty('overflow');
      }
    };
    this.setOpacityForBackDrop = () => {
      var _a, _b;
      const opacityValue = this.opacityOverriden && isPositiveNumber(this.opacity) ? this.opacity : parseFloat(getDesignTokenValue('--one-backdrop-opacity'));
      const backdropBackgroundColor = getDesignTokenValue('--one-color-background-overlay');
      (_b = (_a = this.hostElement) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.setProperty(BACKDROP_OPACITY_VARIABLE, `${alpha(backdropBackgroundColor, opacityValue)}`);
    };
    this.handleMouseDown = ev => this.backdropClicked = ev.target === ev.currentTarget;
    this.onBackdropClicked = () => {
      this.backdropClicked && this.backdropClick.emit();
      this.backdropClicked = false;
    };
    this.opacity = DEFAULT_OPACITY;
    this.disableScrollLock = false;
    this.hidden = false;
    this.opacityOverriden = false;
  }
  handleThemeChanged() {
    this.setOpacityForBackDrop();
  }
  onContentChange() {
    this.handleBodyOverflow();
  }
  handleDisableScrollLockChanges() {
    this.handleBodyOverflow();
  }
  handleOpacityChange() {
    this.opacityOverriden = true;
    this.setOpacityForBackDrop();
  }
  connectedCallback() {
    clearTimeout(this.timeoutID);
    this.handleDisableScrollLockChanges();
  }
  componentWillLoad() {
    this.opacityOverriden = !this.opacity;
  }
  componentDidLoad() {
    this.handleDisableScrollLockChanges();
    this.setOpacityForBackDrop();
  }
  disconnectedCallback() {
    this.timeoutID = setTimeout(() => {
      var _a, _b;
      (_b = (_a = getBody()) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.removeProperty('overflow');
    }, STYLE_CLEAR_TIMEOUT);
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      hidden: this.hidden
    });
    const contentClasses = this.component.getChildBemClasses('backdrop-content', hostClasses);
    return h(Host, {
      key: '27aa4d68ab61abc0afddb9c2ed3600ca8e602cd3',
      class: hostClasses,
      tabIndex: this.hidden ? -1 : this.component.getInitialTabIndex(),
      onClick: this.onBackdropClicked,
      onMouseDown: this.handleMouseDown
    }, h("div", {
      key: '0506a6d4f89628a47405e39e074bb5f36ee263da',
      class: contentClasses
    }, h("slot", {
      key: 'afe5fc4deac8c6201d7596de128070a678d57e62',
      ref: el => {
        this.defaultSlotRef = el;
        this.onContentChange();
      }
    })));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "hidden": ["handleDisableScrollLockChanges"],
      "disableScrollLock": ["handleDisableScrollLockChanges"],
      "opacity": ["handleOpacityChange"]
    };
  }
  static get style() {
    return OwcBackdropStyle0;
  }
}, [6, "owc-backdrop", {
  "opacity": [514],
  "disableScrollLock": [516, "disable-scroll-lock"],
  "hidden": [516],
  "opacityOverriden": [32]
}, [[4, "themeChanged", "handleThemeChanged"]], {
  "hidden": ["handleDisableScrollLockChanges"],
  "disableScrollLock": ["handleDisableScrollLockChanges"],
  "opacity": ["handleOpacityChange"]
}]);
__decorate([OnElementMutation({
  target: self => self.hostElement,
  attributes: true,
  childList: true,
  subtree: true,
  characterData: true
})], Backdrop.prototype, "onContentChange", null);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-backdrop"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-backdrop":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, Backdrop);
        }
        break;
    }
  });
}
export { Backdrop as B, defineCustomElement as d };